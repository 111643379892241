import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent {
  isSmall = false;
  constructor(private breakpointObserver: BreakpointObserver) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
  }
  // reason = [
  //   {
  //     img: '',
  //     text1: '',
  //     text2: '',
  //   },
  // ];
  // list = [
  //   {
  //     title: '',
  //     content: '',
  //   },
  // ];

  download() {
    window.open('https://www.mtlogin.com/download.html');
  }
}
