import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder } from '@angular/forms';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { WorkorderApiService } from 'src/app/service/workorder-api.service';
import { EventEmitter } from 'stream';

@Component({
  selector: 'app-subscription-select',
  templateUrl: './subscription-select.component.html',
  styleUrls: ['./subscription-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SubscriptionSelectComponent,
    },
  ],
})
export class SubscriptionSelectComponent
  extends AbstractNgModelComponent
  implements OnInit
{
  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef })
  userMenu!: ElementRef<HTMLElement>;
  localStorageLanguage = '';

  get userMenuData() {
    return {
      menuWidth: this.userMenu.nativeElement.clientWidth,
    };
  }

  value: any;

  isDropdownOpen = false;
  src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';

  constructor(
    private elementRef: ElementRef,
    private fb: UntypedFormBuilder,
    private api: WorkorderApiService
  ) {
    super();
    this.localStorageLanguage = localStorage.getItem('language') || '';
  }

  ngOnInit(): void {
    this.findSeats();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.src = '../../../../assets/images/withdraw/Icon-up-arrow-min.svg';
    } else {
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDropdownOpen = false;
      this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    }
  }

  override onChange(): void {
    super.onChange();
  }

  selectOption(option: any) {
    this.isDropdownOpen = false;
    this.src = '../../../../assets/images/withdraw/Icon-down-arrow-min.svg';
    this.value = option;
    this.onChange();
  }

  seats: any = [];
  async findSeats() {
    try {
      const r = await this.api.findSeatInfo();
      this.seats = r.data || [];
    } catch (error) {
    } finally {
    }
  }
}
