import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { MessageService } from 'src/app/service/message.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-share-bottom-sheet',
  templateUrl: './share-bottom-sheet.component.html',
  styleUrls: ['./share-bottom-sheet.component.scss'],
})
export class ShareBottomSheetComponent implements OnInit {
  socialMedial: any;
  pageAffiliate: any;
  constructor(
    private bottomSheetRef: MatBottomSheetRef<ShareBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private clipboard: Clipboard,
    private message: MessageService,
    private translateService: TranslateService
  ) {
    this.socialMedial = [
      {
        src: '../../../../assets/images/affiliate/share-Facebook.webp',
        href:
          'https://www.facebook.com/sharer/sharer.php?u=https://goingbus.com?s=' +
          data,
      },
      {
        src: '../../../../assets/images/affiliate/share-Twitter.webp',
        href:
          'https://twitter.com/intent/tweet?text=Shared%20streaming%20account%20on%20GoingBus%20trust%20meets%20savings,%20Join%20In%20%26%20Save%20Up%20To%2080%25!%20%20%20https%3A//goingbus.com?s=' +
          data,
      },
      {
        src: '../../../../assets/images/affiliate/share-Telegram.webp',
        href:
          'https://t.me/share/url?url=https%3A//goingbus.com?s=' +
          data +
          '&text=Shared%20streaming%20account%20on%20GoingBus%20trust%20meets%20savings,%20Join%20In%20%26%20Save%20Up%20To%2080%25!',
      },
      {
        src: '../../../../assets/images/affiliate/share-Naver.webp',
        href: '',
      },
      {
        src: '../../../../assets/images/affiliate/share-Kakao.webp',
        href: '',
      },
      {
        src: '../../../../assets/images/affiliate/share-Line.webp',
        href: 'https://line.me/R/msg/text/?https://goingbus.com?s=' + data,
      },
      {
        src: '../../../../assets/images/affiliate/share-WhatsApp.webp',
        href:
          'https://api.whatsapp.com/send?text=https%3A%2F%2Fgoingbus.com%3Fs%3D' +
          data,
      },
      {
        src: '../../../../assets/images/affiliate/share-Reddit.webp',
        href:
          'https://reddit.com/submit?url=https%3A%2F%2Fgoingbus.com%3Fs%3Dfijv2VZH' +
          data +
          '&title=Shared%20streaming%20account%20on%20GoingBus%20trust%20meets%20savings,%20Join%20In%20%26%20Save%20Up%20To%2080%25!',
      },
      {
        src: '../../../../assets/images/affiliate/share-Pinterest.webp',
        href:
          'http://pinterest.com/pin/create/button/?url=https%3A%2F%2Fgoingbus.com%3Fs%3D' +
          data +
          '&media=&description=Shared%20streaming%20account%20on%20GoingBus%20trust%20meets%20savings,%20Join%20In%20%26%20Save%20Up%20To%2080%25!',
      },
      {
        src: '../../../../assets/images/affiliate/share-Messenger.webp',
        href: '',
      },
      {
        src: '../../../../assets/images/affiliate/share-VK.webp',
        href: 'https://vk.com/share.php?url=https://goingbus.com?s=' + data,
      },
      {
        src: '../../../../assets/images/affiliate/share-XING.webp',
        href:
          'https://www.xing.com/app/user?op=share&url=https://goingbus.com?s=' +
          data,
      },
      {
        src: '../../../../assets/images/affiliate/share-Email.webp',
        href:
          'mailto:?subject=Shared%20streaming%20account%20on%20GoingBus%20trust%20meets%20savings,Join%20In%20%26%20Save%20Up%20To%2080%25!%20%20&body=https%3A//goingbus.com?s=' +
          data,
      },
      {
        src: '../../../../assets/images/affiliate/share-Message.webp',
        href: 'sms:&body=https://goingbus.com?s=' + data,
      },
    ];
  }

  ngOnInit(): void {
    this.translateService.get('pageAffiliate').subscribe((text: string) => {
      this.pageAffiliate = text;
    });
  }

  close(r: any) {
    this.bottomSheetRef.dismiss(r);
  }
  copy() {
    this.clipboard.copy(environment.http + this.data);
    this.message.info(this.pageAffiliate.CopySuccessful);
  }
}
