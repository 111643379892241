import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IPayPalConfig } from 'ngx-paypal';
import { MessageService } from 'src/app/service/message.service';
import { OrderApiService } from 'src/app/service/order-api.service';
import { RegionApiService } from 'src/app/service/region-api.service';
import { environment } from 'src/environments/environment';
declare var Airwallex: any;

@Component({
  selector: 'app-payment-select-modal',
  templateUrl: './payment-select-modal.component.html',
  styleUrls: ['./payment-select-modal.component.scss'],
})
export class PaymentSelectModalComponent implements OnInit {
  public payPalConfig!: IPayPalConfig;
  textMessage: any;

  constructor(
    public dialogRef: MatDialogRef<PaymentSelectModalComponent>,
    private api: OrderApiService,
    private message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private apiRegion: RegionApiService
  ) {}

  currency: any = this.data.currency.toUpperCase();

  ngOnInit(): void {
    this.methodSelected.method = 'airwallex'; //默认选项
    // this.methodSelected.method = 'paypal'; //默认选项

    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
    this.findPayssionPayments();
    this.paypalPayment();
    Airwallex.init({
      env: environment.airwallexEnv,
      origin: window.location.origin,
    });
    this.getIpRegion();
  }

  paypalPayment() {
    this.payPalConfig = {
      currency: this.currency,
      clientId: environment.paypalClientID,
      style: { shape: 'pill' },
      createOrderOnServer: async (data: any) => {
        try {
          const r = await this.api.createUserOrder(
            this.data.busSeatID,
            this.data.busTypeID,
            this.data.exclusive,
            this.data.promoCode,
            this.data.currency
          );
          if (r.status === 1001) {
            this.message.warn(this.textMessage.ProductOffline);
          } else if (r.status === 1004) {
            this.message.warn(this.textMessage.BusTypeNotRenewable);
          } else if (r.status === 1005) {
            this.message.warn(this.textMessage.ProductNotRenewable);
          } else if (r.status === 1203) {
            this.message.warn(this.textMessage.CodeNotExist);
          } else if (r.status === 1204) {
            this.message.warn(this.textMessage.CodeExpired);
          } else if (r.status === 1205) {
            this.message.warn(this.textMessage.CodeNotValid);
          } else if (r.status === 1206) {
            this.message.warn(this.textMessage.CodeUsageLimit);
          } else if (r.status === 1207) {
            this.message.warn(this.textMessage.CodeNotApplicable);
          } else if (r.status === 1208) {
            this.message.warn(this.textMessage.CodeUsed);
          } else if (r.status === 1209) {
            this.message.warn(this.textMessage.FirstOrder);
          } else if (r.status === 1210) {
            this.message.warn(this.textMessage.FirstProduct);
          } else if (r.status === 1211) {
            this.message.warn(this.textMessage.RenewalAvailable);
          } else if (r.status === 1212) {
            this.message.warn(this.textMessage.NonRenewal);
          } else if (r.status !== 0) {
            this.message.warn(this.textMessage.Unknown);
          } else {
            return r.data.id;
          }
        } catch (error) {
          this.message.error(this.textMessage.NetworkError);
        }
        return '';
      },
      onApprove: (data: any, actions: any) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((details: any) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );
        });
      },
      onClientAuthorization: (data: any) => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
        this.dialogRef.close(data);
      },
      onCancel: (data: any, actions: any) => {
        console.log('OnCancel', data, actions);
      },
      onError: (err: any) => {
        console.log('OnError', err);
      },
      onClick: (data: any, actions: any) => {
        console.log('onClick', data, actions);
      },
    };
  }

  loading = false;
  payments: any;
  async findPayssionPayments() {
    try {
      this.loading = true;
      const r = await this.api.findPayssionPayments();
      this.payments = r.data || [];
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  methodSelected: any = { method: '', submethod: '' };

  setMethodSelected(method: any) {
    this.methodSelected.method = method;
    if (method === 'airwallex') this.methodSelected.submethod = '';
  }

  pay(method: string, p: string) {
    this.createUserOrder(method, p);
  }

  async createUserOrder(method: string, pmid: string) {
    try {
      const r = await this.api.createUserOrder(
        this.data.busSeatID,
        this.data.busTypeID,
        this.data.exclusive,
        this.data.promoCode,
        this.data.currency,
        method,
        pmid
      );
      if (r.status === 1001) {
        this.message.warn(this.textMessage.ProductOffline);
      } else if (r.status === 1004) {
        this.message.warn(this.textMessage.BusTypeNotRenewable);
      } else if (r.status === 1005) {
        this.message.warn(this.textMessage.ProductNotRenewable);
      } else if (r.status === 1203) {
        this.message.warn(this.textMessage.CodeNotExist);
      } else if (r.status === 1204) {
        this.message.warn(this.textMessage.CodeExpired);
      } else if (r.status === 1205) {
        this.message.warn(this.textMessage.CodeNotValid);
      } else if (r.status === 1206) {
        this.message.warn(this.textMessage.CodeUsageLimit);
      } else if (r.status === 1207) {
        this.message.warn(this.textMessage.CodeNotApplicable);
      } else if (r.status === 1208) {
        this.message.warn(this.textMessage.CodeUsed);
      } else if (r.status === 1209) {
        this.message.warn(this.textMessage.FirstOrder);
      } else if (r.status === 1210) {
        this.message.warn(this.textMessage.FirstProduct);
      } else if (r.status === 1211) {
        this.message.warn(this.textMessage.RenewalAvailable);
      } else if (r.status === 1212) {
        this.message.warn(this.textMessage.NonRenewal);
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        if (this.methodSelected.method === 'Payssion') this.gotoPayment(r.data);
        else this.redirectToCheckout(r.data);
      }
    } catch (error) {}
  }

  gotoPayment(data: any) {
    var form = document.createElement('form');
    form.style.visibility = 'hidden';
    form.method = 'POST';
    form.action = 'https://payssion.com/payment/create.html';
    var input = document.createElement('input');
    input.name = 'api_key';
    input.value = data.key;
    form.appendChild(input);
    var input = document.createElement('input');
    input.name = 'api_sig';
    input.value = data.sig;
    form.appendChild(input);
    var input = document.createElement('input');
    input.name = 'pm_id';
    input.value = data.pm_id;
    form.appendChild(input);
    var input = document.createElement('input');
    input.name = 'order_id';
    input.value = data.id;
    form.appendChild(input);
    var input = document.createElement('input');
    input.name = 'description';
    input.value = 'Charge';
    form.appendChild(input);
    var input = document.createElement('input');
    input.name = 'amount';
    input.value = `${data.pay_price}`;
    form.appendChild(input);
    var input = document.createElement('input');
    input.name = 'currency';
    input.value = data.currency;
    form.appendChild(input);

    document.body.appendChild(form); // forms cannot be submitted outside of body
    form.submit(); // send the payload and navigate
  }

  airwallexLocale = [
    'en',
    'zh',
    'ja',
    'ko',
    'ar',
    'es',
    'de',
    'fr',
    'it',
    'nl',
  ];
  redirectToCheckout(data: any): void {
    var locale = localStorage.getItem('language') || 'en';
    if (locale.includes('_')) {
      locale = locale.split('_')[0];
    } else if (locale.includes('-')) {
      locale = locale.split('-')[0];
    }

    if (!this.airwallexLocale.includes(locale)) {
      locale = 'en';
    }

    // Add a button handler to trigger the redirect to HPP
    Airwallex.redirectToCheckout({
      env: environment.airwallexEnv,
      mode: 'payment',
      intent_id: data.payment_intent_id,
      client_secret: data.client_secret,
      currency: data.currency,
      successUrl: 'https://goingbus.com/subscription',
      failUrl: 'https://goingbus.com',
      locale: locale,
      withBilling: true,
      requiredBillingContactFields: ['name', 'email'],
      applePayRequestOptions: {
        buttonType: 'buy', // Indicate the type of button you want displayed on your payments form. Like 'buy'
        buttonColor: 'white-with-line', // Indicate the color of the button. Default value is 'black'
        countryCode: this.region.toUpperCase(), // The merchant's two-letter ISO 3166 country code. Like 'HK'
        // totalPriceLabel: 'COMPANY, INC.', // Provide a business name for the label field.
      },
      googlePayRequestOptions: {
        countryCode: this.region.toUpperCase(),
        // merchantInfo: {
        //   merchantName: 'Example Merchant',
        // },
        emailRequired: true,
        billingAddressRequired: true,
        buttonType: 'book',
        buttonColor: 'black',
        buttonSizeMode: 'fill',
      },
    });
  }

  region = '';
  async getIpRegion() {
    try {
      const r = await this.apiRegion.getIpRegion();
      this.region = r.data;
    } catch (error) {}
  }
}
