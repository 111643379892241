import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-carousel-img',
  templateUrl: './carousel-img.component.html',
  styleUrls: ['./carousel-img.component.scss'],
})
export class CarouselImgComponent {
  reviewData: any = [
    {
      href: 'https://x.com/prem_login',
      src: '../../../../assets/images/page-footer/img-Twitter.png',
    },
    {
      href: 'https://www.youtube.com/@premlogin',
      src: '../../../../assets/images/page-footer/img-Youtube.png',
    },
    {
      href: 'https://www.instagram.com/prem_login?igsh=MTNiamhmMnhkc20wbQ%3D%3D&utm_source=qr',
      src: '../../../../assets/images/page-footer/img-Instagram.png',
    },
    {
      href: 'https://www.facebook.com/profile.php?id=61565143223209',
      src: '../../../../assets/images/page-footer/img-Facebook.png',
    },
    // {
    //   href: 'https://t.me/goingbus',
    //   src: '../../../../assets/images/page-footer/img-Telegram.webp',
    // },
    {
      href: 'https://open.kakao.com/o/g4fap5Kg',
      src: '../../../../assets/images/page-footer/img-Kakao.png',
    },
    {
      href: 'https://www.tiktok.com/@premlogin',
      src: '../../../../assets/images/page-footer/img-Toktik.png',
    },
    {
      href: 'https://wa.me/message/ZTWLG66XYQQFC1',
      src: '../../../../assets/images/page-footer/img-Phone.png',
    },
  ];
  activeIndex: number = 0;
  prevIndex: number = 0;
  nextIndex: number = 0;
  pre2Index: number = 0;
  next2Index: number = 0;
  pre3Index: number = 0;
  next3Index: number = 0;
  next4Index: number = 0;

  interval: any;

  constructor(private elementRef: ElementRef) {
    this.interval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  ngOnInit() {
    this.showSlide(this.activeIndex);
    this.setupSwipeGesture();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  showSlide(index: number) {
    this.activeIndex = index;
    this.prevOrNextIndex();
  }

  nextSlide() {
    this.activeIndex = (this.activeIndex + 1) % this.reviewData.length;
    this.prevOrNextIndex();
  }

  prevOrNextIndex() {
    this.prevIndex =
      this.activeIndex === 0
        ? this.reviewData.length - 1
        : this.activeIndex - 1;
    this.nextIndex =
      this.activeIndex === this.reviewData.length - 1
        ? 0
        : this.activeIndex + 1;

    this.pre2Index =
      this.prevIndex === 0 ? this.reviewData.length - 1 : this.prevIndex - 1;

    this.next2Index =
      this.activeIndex === this.reviewData.length - 2 ? 0 : this.nextIndex + 1;

    this.pre3Index =
      this.pre2Index === 0 ? this.reviewData.length - 1 : this.pre2Index - 1;

    this.next3Index =
      this.activeIndex === this.reviewData.length - 3 ? 0 : this.next2Index + 1;
    this.next4Index =
      this.activeIndex === this.reviewData.length - 4 ? 0 : this.next3Index + 1;
  }

  prevSlide() {
    this.activeIndex =
      (this.activeIndex - 1 + this.reviewData.length) % this.reviewData.length;
    this.prevOrNextIndex();
  }

  setupSwipeGesture(): void {
    const element = this.elementRef.nativeElement.querySelector(
      '.carousel-container'
    );
    const hammer = new Hammer(element);

    hammer.on('swipeleft', () => {
      this.nextSlide();
    });

    hammer.on('swiperight', () => {
      this.prevSlide();
    });
  }

  toSlide(i: number) {
    this.activeIndex = i;
    this.prevOrNextIndex();
  }
}
