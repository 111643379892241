<div class="card">
  <div class="close">
    <button mat-dialog-close>
      <img
        alt="close"
        width="24"
        height="24"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div class="title">{{ "NetflixCodeModal.Title" | translate }}</div>
  
  <div class="tabs">
    <div
      *ngFor="let data of [1, 2, 3, 4, 5]"
      class="tab"
      (click)="tab(data)"
      [ngClass]="{ active: data === activeIndex }"
    >{{ data }}</div>
  </div>
  
  <div class="content" [ngClass]="{ active: 1 === activeIndex }">
    <div class="step"><span>{{ "NetflixCodeModal.Step1" | translate }}</span></div>
    <div class="tip">
      <img
        src="../../../../assets/images/subscription/Icon-attention.svg"
        alt="attention"
        width="20"
        height="20"
      />
      {{ "NetflixCodeModal.Tip" | translate }}
    </div>
    <div class="detail">
      {{ "NetflixCodeModal.Detail1" | translate }} <br />
      <ng-container>
        <div class="img-container">
          <img
            src="../../../../assets/images/subscription/step1-1.png"
            alt=""
            width="190"
            height="253"
          />
          <img
            src="../../../../assets/images/subscription/step1-2.png"
            alt=""
            height="253"
          />
        </div>
        
      </ng-container>
      <!-- <ng-container *ngIf="language === 'ko'">
        <img
          src="../../../../assets/images/subscription/step1-ko.webp"
          alt=""
          width="542"
          height="253"
        />
      </ng-container> -->
    </div>
  </div>
  <div class="content" [ngClass]="{ active: 2 === activeIndex }">
    <div class="step"><span>{{ "NetflixCodeModal.Step2" | translate }}</span></div>
    <div class="detail">
      {{ "NetflixCodeModal.Detail2" | translate }}<br />
      <ng-container>
        <div class="img-container">
          <img
            src="../../../../assets/images/subscription/step2-1.png"
            alt=""
            width="190"
            height="253"
          />
          <img
            src="../../../../assets/images/subscription/step2-2.png"
            alt=""
            height="253"
          />
        </div>
      </ng-container>
      
      <!-- <ng-container *ngIf="language === 'ko'">
        <img
          src="../../../../assets/images/subscription/step2-ko.webp"
          alt=""
          width="542"
          height="253"
        />
      </ng-container> -->
    </div>
  </div>
  <div class="content" [ngClass]="{ active: 3 === activeIndex }">
    <div class="step"><span>{{ "NetflixCodeModal.Step3" | translate }}</span></div>
    <div class="detail">
      {{ "NetflixCodeModal.Detail3" | translate }}<br />
      <div class="img-container">
        <img
          src="../../../../assets/images/subscription/step3.png"
          alt=""
          height="253"
        />
      </div>
    </div>
  </div>
  <div class="content" [ngClass]="{ active: 4 === activeIndex }">
    <div class="step"><span>{{ "NetflixCodeModal.Step4" | translate }}</span></div>
    <div class="detail">
      {{ "NetflixCodeModal.Detail4" | translate }}<br />
      <ng-container
        >
        <div class="img-container">
          <img
            src="../../../../assets/images/subscription/step4.png"
            alt=""
            height="253"
          />
        </div>
      </ng-container>
    </div>
  </div>
  <div class="content" [ngClass]="{ active: 5 === activeIndex }">
    <div class="step"><span>{{ "NetflixCodeModal.Step5" | translate }}</span></div>
    <div class="detail">
      {{ "NetflixCodeModal.Detail5" | translate }}<br />
      <ng-container>
        <div class="img-container">
          <img
            src="../../../../assets/images/subscription/step5.png"
            alt=""
            height="253"
          />
        </div>
      </ng-container>
    </div>
  </div>

  
  <div
    class="button"
    *ngFor="let index of [1, 2, 3, 4]"
    [ngClass]="{ active: index === activeIndex }"
  >
    <app-button
      class="button-step1"
      fontColor="#ffffff"
      width="245px"
      height="40px"
      (click)="next(index)"
      type="0"
    >
      {{ "NetflixCodeModal.Next" | translate }}
    </app-button>
    <app-button
      class="button-step1"
      fontColor="#ffffff"
      width="245px"
      height="40px"
      (click)="skip()"
    >
      {{ "NetflixCodeModal.Skip" | translate }}
    </app-button>
  </div>
  
  <div class="button" [ngClass]="{ active: 5 === activeIndex }">
    <app-button
      style="width: 100%"
      color="#612069"
      fontColor="#ffffff"
      width="100%"
      height="40px"
      (click)="onOk()"
    >
      {{ "NetflixCodeModal.GetLink" | translate }}
    </app-button>
  </div>
  
</div>
