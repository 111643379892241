<div class="outside">
  <div class="close">
    <button mat-dialog-close>
      <img
        alt="del"
        width="50"
        height="50"
        src="../../../../assets/images/base/Icon-del-default.svg"
      />
    </button>
  </div>
  <div class="title">
    {{ "JoinGroup.Title" | translate }}
  </div>
  <div class="content" mat-dialog-content>
    <a class="item" href="https://www.youtube.com/@premlogin" target="_blank">
      <img
        src="../../../../assets/images/affiliate/group-youtube.svg"
        alt="social"
        height="68"
        width="68"
      />
    </a>
    <a class="item" href="https://www.instagram.com/prem_login?igsh=MTNiamhmMnhkc20wbQ%3D%3D&utm_source=qr" target="_blank">
      <img
        src="../../../../assets/images/affiliate/group-ins.svg"
        alt="social"
        height="68"
        width="68"
      />
    </a>
    <a class="item" href="https://www.facebook.com/profile.php?id=61565143223209" target="_blank">
      <img
        src="../../../../assets/images/affiliate/group-facebook.svg"
        alt="social"
        height="68"
        width="68"
      />
    </a>
    <a class="item" href="https://x.com/prem_login" target="_blank">
      <img
        src="../../../../assets/images/affiliate/group-twitter.svg"
        alt="social"
        height="68"
        width="68"
      />
    </a>
    <a class="item" href="https://open.kakao.com/o/g4fap5Kg" target="_blank">
      <img
        src="../../../../assets/images/affiliate/group-talk.svg"
        alt="social"
        height="68"
        width="68"
      />
    </a>
    <a class="item" href="https://wa.me/message/ZTWLG66XYQQFC1" target="_blank">
      <img
        src="../../../../assets/images/affiliate/group-whatsapp.svg"
        alt="social"
        height="68"
        width="68"
      />
    </a>
    <a class="item" href="https://www.tiktok.com/@premlogin" target="_blank">
      <img
        src="../../../../assets/images/affiliate/group-tiktok.svg"
        alt="social"
        height="68"
        width="68"
      />
    </a>
    <!-- <a
      class="item"
      href="https://www.whatsapp.com/channel/0029VaAowhr90x2uAws97Z1I"
      target="_blank"
    >
      <img
        src="../../../../assets/images/affiliate/share-WhatsApp.webp"
        alt="social"
        height="68"
        width="68"
      />
    </a>
    <a class="item" href="https://t.me/GoingBus" target="_blank">
      <img
        src="../../../../assets/images/affiliate/share-Telegram.webp"
        alt="social"
        height="68"
        width="68"
      />
    </a>
    <a class="item" href="https://open.kakao.com/o/gHcm3LKf" target="_blank">
      <img
        src="../../../../assets/images/affiliate/share-Kakao.webp"
        alt="social"
        height="68"
        width="68"
      />
    </a> -->
  </div>
</div>