import { Component, Input } from '@angular/core';
import { OnInit } from '@angular/core';
@Component({
  selector: 'app-total-earned-card',
  templateUrl: './total-earned-card.component.html',
  styleUrls: ['./total-earned-card.component.scss'],
})
export class TotalEarnedCardComponent implements OnInit {
  @Input() data: any;
  total_commission_usd: any = 0;
  total_commission_eur: any = 0;
  available_commission_usd: any = 0;
  available_commission_eur: any = 0;
  ngOnInit(): void {
      this.total_commission_usd += Math.round(parseFloat(this.data.total_commission.usd))
      this.total_commission_eur += Math.round(parseFloat(this.data.total_commission.eur))
      this.available_commission_usd += Math.round(parseFloat(this.data.availabel_commission.usd))
      this.available_commission_eur += Math.round(parseFloat(this.data.available_commission.eur))
  }
}
