<!-- <div class="container">
  <app-spinner [loading]="loading">
    <div>
      <app-platform-card
        *ngFor="let platform of platforms"
        [data]="platform"
      ></app-platform-card>
    </div>
  </app-spinner>
</div> -->
<mat-tab-group mat-align-tabs="center">
  <mat-tab>
    <ng-template mat-tab-label>
      Best Sellers
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of best_sellers"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      Streaming
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of streaming"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      AI Tools
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of ai_tools"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      Music
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of music"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      Others
    </ng-template>
    <ng-template matTabContent>
      <app-spinner [loading]="loading">
        <div>
          <app-platform-card
            *ngFor="let data of others"
            [data]="data"
          ></app-platform-card>
        </div>
      </app-spinner>
    </ng-template>
  </mat-tab>

</mat-tab-group>
