<div class="container">
  <article>
    <h1>⽇本信息</h1>
    <h2>特定商取引法に基づく表記</h2>
    <h4>1.運営サイト名</h4>
    <p>goingbus.com</p>
    <h4>2.ドメイン</h4>
    <p>goingbus.com</p>
    <h4>3.設⽴年⽉⽇</h4>
    <p>2023年2⽉</p>
    <h4>4.会社名</h4>
    <p>
      HONG KONG SHIMEI TECHNOLOGY LIMITED <br />
      Sales responsible representative officer: Lei Huang
    </p>
    <h4>5.所在地</h4>
    <p>
      Company office address: Room 9042, 9/F, Tower B, Chung Mei <br />
      Center, 15-17 Hing Yip, Kwun Tong, Kowloon, Hong Kong. E16 1AH
    </p>
    <h4>6.代表電話番号</h4>
    <p>XXXXXX</p>
    <h4>7.お問い合わせ</h4>
    <p>info@goingbus.com</p>
    <h4>8.営業時間</h4>
    <p>24時間いつでもご注⽂できます。</p>
    <h4>9.注⽂⽅法</h4>
    <p>インターネットによるオンライン注⽂</p>
    <h4>10.⽀払い⽅法</h4>
    <p>PayPal（ペイパル）決済、クレジットカード、コンビニ決済</p>
    <h4>11.商品の引渡し</h4>
    <p>購⼊後24時間以内に即発送、送料無料</p>
    <h4>12.返品・交換</h4>
    <p>https://goingbus.com/refundpolicy</p>
    <h4>13.利⽤規約</h4>
    <p>
      ご利⽤の際は当社利⽤規約に同意したものとみなします。 <br />
      ご注⽂前に必ずご確認ください。
    </p>
    <h4>14.プライバシーポリシー</h4>
    <p>
      当社は当社が定めるプライバシーポリシーに基づいて厳重に個⼈情報
      を保管いたします。
    </p>
  </article>
</div>
