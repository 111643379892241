import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-netflix-code-modal',
  templateUrl: './netflix-code-modal.component.html',
  styleUrls: ['./netflix-code-modal.component.scss'],
})
export class NetflixCodeModalComponent {
  activeIndex = 1;

  language: any;
  constructor(private dialogRef: MatDialogRef<NetflixCodeModalComponent>) {
    this.language = localStorage.getItem('language');
  }

  tab(index: any) {
    this.activeIndex = index;
  }

  next(index: any) {
    this.activeIndex = index + 1;
  }

  skip() {
    this.activeIndex = 5;
  }

  onOk() {
    this.dialogRef.close('ok');
  }
}
