<div class="communication-box" *ngIf="communication">
  <span class="communication-txt" routerLink="/affiliate"><p>Refer Friends, Earn Rewards!</p></span>
  <app-carousel-img></app-carousel-img>
</div>
<div class="container">
  <div class="content">
    <div class="col">
      <span class="title">{{ "pageFooter.About" | translate }}</span>
      <span class="link" routerLink="/aboutus">{{
        "pageFooter.AboutUs" | translate
      }}</span>
      <span class="link" routerLink="/contact">{{
        "pageFooter.ContactUs" | translate
      }}</span>
      <!-- <span class="link">
        <a href="https://blog.goingbus.com/" target="_blank">{{
          "pageFooter.Blog" | translate
        }}</a>
      </span> -->
      <span class="link" routerLink="/affiliate">{{
        "pageFooter.BusinessCooperation" | translate
      }}</span>
      <span class="link" routerLink="/company" *ngIf="region === 'jp'">
        特定商取引法に基づく表記
      </span>
    </div>
    <div class="col">
      <span class="title">{{ "pageFooter.Legal" | translate }}</span>
      <span class="link" routerLink="/terms-condition">{{
        "pageFooter.TermsCondition" | translate
      }}</span>
      <span class="link" routerLink="/privacypolicy">{{
        "pageFooter.PrivacyPolicy" | translate
      }}</span>
      <span class="link" routerLink="/refundpolicy">{{
        "pageFooter.RefundPolicy" | translate
      }}</span>
    </div>
    <div class="col" *ngIf="!isSmall">
      <div class="col">
        <span class="title">{{ "pageFooter.Language" | translate }}</span>
        <span class="link"><app-language-select></app-language-select></span>
      </div>
      <!-- <div class="col">
        <span class="title">{{ "pageFooter.Country" | translate }}</span>
        <span class="link"><app-region-select></app-region-select></span>
      </div> -->
      
      
      
      
    </div>
    <div class="col" *ngIf="isSmall">
      <span class="title">{{ "pageFooter.Language" | translate }}</span>
      <span class="link"><app-language-select></app-language-select></span>
    </div>
    <!-- <div class="col" *ngIf="isSmall">
      <span class="title">{{ "pageFooter.Country" | translate }}</span>
      <span class="link"><app-region-select></app-region-select></span>
    </div> -->
    
    <div class="col">

      <span class="title">{{ "pageFooter.CustomerService" | translate }}</span>
      <span class="email">
        <img src="./../../../../assets/images/page-footer/img-email.png" style="width: 24px; height: 24px;">
        <a class="maillink" href="mailto:info@premlogin.com" style="color: #9CA8CD; text-decoration: none;">info@premlogin.com</a>
        <!-- info@premlogin.com -->
      </span>
      <div class="logo">
        <a href="https://www.trustpilot.com/review/premlogin.com">
          <img src="./../../../../assets/images/page-footer/img-Trustpilot.png" class="img-truspilot">
        </a>
        <a href="https://transparencyreport.google.com/safe-browsing/search?url=https:%2F%2Fwww.premlogin.com">
          <img src="./../../../../assets/images/page-footer/img-GoogleSafe.png" class="img-google-safe">
        </a>
      </div>
      
    </div>
  </div>
</div>

<div class="split-line"></div>

<div class="bottom">
  
  <div class="icons">
    <ng-container *ngFor="let data of payWayIcon">
      <img class="payway-icon" [src]="data.src">
    </ng-container>
  </div>
  
  <div class="copyright" *ngIf="region === 'kr'">
    The right to all copyrights, trademarks, and service marks are held by their respective owners<br>
    Copyright © 2024 PremLogin LIMTED (23 Grange Rood, WAKEFIELD, WF69 5MK). All Rights
    Reserved
  </div>
  <div class="copyright" *ngIf="region !== 'kr'">
    The right to all copyrights, trademarks, and service marks are held by their respective owners<br>
    Copyright © 2024 PremLogin LIMTED (23 Grange Rood, WAKEFIELD, WF69 5MK). All Rights
    Reserved
  </div>
  <div>
    <a routerLink="/terms-condition">TERMS AND CONDITION</a> and
    <a routerLink="/refundpolicy">PRIVACY POLICY</a>
  </div>
</div>
