<app-page-header [backgroundColor]="'#F5ECE3'"></app-page-header>

<div class="header">
  <div class="container">
    <div class="left">
      <ng-container>
        <div class="title" *ngIf="!isSmall">
          {{ "pageLanding.Title" | translate }}
        </div>
        <div
          class="title-small"
          *ngIf="isSmall"
          style="font-family: Arial, Arial; font-weight: 900; font-size: 18px"
        >
          <div style="color: #602369">ChatGPT Plus</div>
          <div>{{ "pageLanding.TitleSmall" | translate }}</div>
        </div>
      </ng-container>

      <div class="content" *ngIf="!isSmall">
        <!-- <div class="step">
          <span>{{ "pageLanding.Text1" | translate }}</span>
        </div> -->
        <div class="step">
          <span>{{ "pageLanding.Text2" | translate }}</span>
        </div>
        <div class="step">{{ "pageLanding.Text3" | translate }}</div>
      </div>
      <button (click)="download()">
        {{ "pageLanding.Download" | translate }}
      </button>
    </div>
    <div class="right">
      <img
        width="357"
        height="355"
        src="../../../assets/images/landing/chatgpt-icon.webp"
        alt="chatgpt"
      />
    </div>
  </div>
</div>

<div class="container">
  <div class="keynote" *ngIf="isSmall">
    <div class="content">
      <div class="step">
        <span>{{ "pageLanding.Text1" | translate }}</span>
      </div>
      <div class="step">
        <span>{{ "pageLanding.Text2" | translate }}</span>
      </div>
      <div class="step">{{ "pageLanding.Text3" | translate }}</div>
    </div>
  </div>
  <div class="introduce">
    <div class="title">
      <img
        width="51"
        height="50"
        src="../../../assets/images/landing/title-icon.webp"
        alt="icon"
      />
      {{ "pageLanding.IntroductionGpt" | translate }}
    </div>

    <div class="content1">
      <div class="why">{{ "pageLanding.WhyGpt" | translate }}</div>
      <div class="reason">
        {{ "pageLanding.Reason" | translate }}
        <!-- <br />
        {{ "pageLanding.Include" | translate }} -->
      </div>
    </div>
    <div class="content2">
      <!-- <div class="item" *ngFor="let data of reason">
        <img width="93" height="89" [src]="data.img" alt="icon" />
        <span>
          <div class="text1">{{ data.text1 }}</div>
          <div class="text2">{{ data.text2 }}</div>
        </span>
      </div> -->
      <div class="item">
        <img
          width="93"
          height="89"
          src="../../../assets/images/landing/Icon-daohanghaiwaigou.webp"
          alt="icon"
        />
        <span>
          <div class="text1">{{ "pageLanding.Reason1" | translate }}</div>
          <div class="text2">
            {{ "pageLanding.Reason1_detail" | translate }}
          </div>
        </span>
      </div>
      <div class="item">
        <img
          width="93"
          height="89"
          src="../../../assets/images/landing/Icon-problemlimitations.webp"
          alt="icon"
        />
        <span>
          <div class="text1">{{ "pageLanding.Reason2" | translate }}</div>
          <div class="text2">
            {{ "pageLanding.Reason2_detail" | translate }}
          </div>
        </span>
      </div>
      <div class="item">
        <img
          width="93"
          height="89"
          src="../../../assets/images/landing/Icon-personaccount.webp"
          alt="icon"
        />
        <span>
          <div class="text1">{{ "pageLanding.Reason3" | translate }}</div>
          <div class="text2">
            {{ "pageLanding.Reason3_detail" | translate }}
          </div>
        </span>
      </div>
      <div class="item">
        <img
          width="93"
          height="89"
          src="../../../assets/images/landing/Icon-price.webp"
          alt="icon"
        />
        <span>
          <div class="text1">{{ "pageLanding.Reason4" | translate }}</div>
          <div class="text2">
            {{ "pageLanding.Reason4_detail" | translate }}
          </div>
        </span>
      </div>
      <div class="item">
        <img
          width="93"
          height="89"
          src="../../../assets/images/landing/Icon-nativeexperience.webp"
          alt="icon"
        />
        <span>
          <div class="text1">{{ "pageLanding.Reason5" | translate }}</div>
          <div class="text2">
            {{ "pageLanding.Reason5_detail" | translate }}
          </div>
        </span>
      </div>
      <div class="item">
        <img
          width="93"
          height="89"
          src="../../../assets/images/landing/Icon-stabilize.webp"
          alt="icon"
        />
        <span>
          <div class="text1">{{ "pageLanding.Reason6" | translate }}</div>
          <div class="text2">
            {{ "pageLanding.Reason6_detail" | translate }}
          </div>
        </span>
      </div>
    </div>
  </div>
  <!-- <div class="tutorials">
    <div class="title">
      <img
        width="51"
        height="50"
        src="../../../assets/images/landing/title-icon.webp"
        alt="icon"
      />
      {{ "pageLanding.Tutorials" | translate }}
    </div>
    <div class="video">
      <vg-player>
        <vg-overlay-play vgFor="video" style="z-index: 0"></vg-overlay-play>
        <video
          [vgMedia]="$any(video)"
          #video
          id="video"
          preload="auto"
          controls
        >
          <source
            src="../../../assets/video/landing-video.mp4"
            type="video/mp4"
          />
        </video>
      </vg-player>
    </div>
    <div class="text">
      {{ "pageLanding.TutorialsText" | translate }}
    </div>
  </div> -->
  <div class="tutorials">
    <div class="title">
      <img
        width="51"
        height="50"
        src="../../../assets/images/landing/title-icon.webp"
        alt="icon"
      />
      {{ "pageLanding.Tutorials" | translate }}
    </div>
    <div class="item">
      <div class="pic">
        <img
          width="564"
          height="330"
          src="../../../assets/images/landing/step1.webp"
          alt="step"
        />
      </div>
      <div class="content left">
        <div class="step">
          <span>{{ "pageLanding.Step1" | translate }}</span
          >{{ "pageLanding.Browser" | translate }}
        </div>
        <div class="detail">{{ "pageLanding.Detail1" | translate }}</div>
        <div class="detail button" (click)="download()">
          {{ "pageLanding.Download" | translate }}>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="item" *ngIf="!isSmall">
        <div class="content right">
          <div class="step">
            <span>{{ "pageLanding.Step2" | translate }}</span
            >{{ "pageLanding.Browser" | translate }}
          </div>
          <div class="detail">{{ "pageLanding.Detail2" | translate }}</div>
        </div>
        <div class="pic">
          <img
            width="564"
            height="330"
            src="../../../assets/images/landing/step2.webp"
            alt="step"
          />
        </div>
      </div>
      <div class="item" *ngIf="isSmall">
        <div class="pic">
          <img
            width="564"
            height="330"
            src="../../../assets/images/landing/step2.webp"
            alt="step"
          />
        </div>
        <div class="content left">
          <div class="step">
            <span>{{ "pageLanding.Step2" | translate }}</span
            >{{ "pageLanding.Browser" | translate }}
          </div>
          <div class="detail">{{ "pageLanding.Detail2" | translate }}</div>
        </div>
      </div>
    </ng-container>
    <div class="item">
      <div class="pic">
        <img
          width="564"
          height="330"
          src="../../../assets/images/landing/step3.webp"
          alt="step"
        />
      </div>
      <div class="content left">
        <div class="step">
          <span>{{ "pageLanding.Step3" | translate }}</span>
        </div>
        <div class="detail">{{ "pageLanding.Detail3" | translate }}</div>
      </div>
    </div>
    <ng-container>
      <div class="item" *ngIf="!isSmall">
        <div class="content right">
          <div class="step">
            <span>{{ "pageLanding.Step4" | translate }}</span>
          </div>
          <div class="detail">{{ "pageLanding.Detail4" | translate }}</div>
        </div>
        <div class="pic">
          <img
            width="564"
            height="330"
            src="../../../assets/images/landing/step4.webp"
            alt="step"
          />
        </div>
      </div>
      <div class="item" *ngIf="isSmall">
        <div class="pic">
          <img
            width="564"
            height="330"
            src="../../../assets/images/landing/step4.webp"
            alt="step"
          />
        </div>
        <div class="content left">
          <div class="step">
            <span>{{ "pageLanding.Step4" | translate }}</span>
          </div>
          <div class="detail">{{ "pageLanding.Detail4" | translate }}</div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="question">
    <div class="title">
      <img
        width="51"
        height="50"
        src="../../../assets/images/landing/title-icon.webp"
        alt="icon"
      />
      {{ "pageLanding.Faq" | translate }}
    </div>
    <mat-accordion>
      <!-- <mat-expansion-panel *ngFor="let data of list">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ data.title }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ data.content }}
        </p>
      </mat-expansion-panel> -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "pageLanding.Faq2" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ "pageLanding.Faq2Answer" | translate }}
        </p> </mat-expansion-panel
      ><mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "pageLanding.Faq3" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ "pageLanding.Faq3Answer" | translate }}
        </p> </mat-expansion-panel
      ><mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "pageLanding.Faq4" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ "pageLanding.Faq4Answer" | translate }}
        </p> </mat-expansion-panel
      ><mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "pageLanding.Faq5" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ "pageLanding.Faq5Answer" | translate }}
        </p> </mat-expansion-panel
      ><mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "pageLanding.Faq6" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ "pageLanding.Faq6Answer" | translate }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "pageLanding.Faq7" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ "pageLanding.Faq7Answer" | translate }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "pageLanding.Faq8" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ "pageLanding.Faq8Answer" | translate }}
        </p>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ "pageLanding.Faq9" | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          {{ "pageLanding.Faq9Answer" | translate }}
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
<div class="footer"><app-page-footer></app-page-footer></div>
