import { Component, OnDestroy, OnInit } from '@angular/core';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Subscription, lastValueFrom } from 'rxjs';
import { UserApiService } from 'src/app/service/user-api.service';
import { MessageService } from 'src/app/service/message.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/service/store/user.service';
import { Crisp } from 'crisp-sdk-web';
import { FileApiService } from 'src/app/service/file-api.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { TranslateService } from '@ngx-translate/core';
import { LoadingDataService } from 'src/app/service/store/loading-data.service';

@Component({
  selector: 'app-login-or-sign-up-card',
  templateUrl: './login-or-sign-up-card.component.html',
  styleUrls: ['./login-or-sign-up-card.component.scss'],
})
export class LoginOrSignUpCardComponent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  error = { required: false, email: false };
  textMessage: any;

  constructor(
    private authService: SocialAuthService,
    private api: UserApiService,
    private message: MessageService,
    private router: Router,
    private userService: UserService,
    private fileapi: FileApiService,
    private fb: UntypedFormBuilder,
    private title: Title,
    private meta: Meta,
    private recaptchaV3Service: ReCaptchaV3Service,
    private translateService: TranslateService,
    private loadingDataService: LoadingDataService
  ) {
    this.title.setTitle('Login - PremLogin');

    this.meta.updateTag({
      name: 'description',
      content: 'Login PremLogin for better experience.',
    });

    this.formGroup = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.formGroup.valueChanges.subscribe((newValues) => {
      this.error.email = false;
      this.error.required = false;
    });

    this.translateService.onLangChange.subscribe((text: any) => {
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });

    this.googleSubscription = this.authService.authState.subscribe((user) => {
      this.loginWithGoogle(user.email, user.name, user.photoUrl, user.idToken);
    });
  }

  googleSubscription: Subscription;

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
  }

  ngOnDestroy(): void {
    this.googleSubscription.unsubscribe();
  }

  checkError() {
    const errors = this.formGroup.controls['email'].errors;
    if (errors && errors['required']) {
      this.error.required = true;
      this.error.email = false;
    } else if (errors && errors['email']) {
      this.error.email = true;
      this.error.required = false;
    }
  }

  async continue() {
    this.checkError();
    if (!(!this.error.required && !this.error.email)) return;

    const email = this.formGroup.value.email;
    const token = await lastValueFrom(
      this.recaptchaV3Service.execute('checkEmail')
    );
    if (!token) {
      this.message.error('recaptcha failed');
      return;
    }
    try {
      this.loading = true;
      this.loadingDataService.set(this.loading);
      const r = await this.api.getCheckEmail(email, token);
      if (r.data === true) {
        this.router.navigate(['/login/password'], {
          queryParams: { email: email },
        });
      } else {
        this.router.navigate(['/login/email/signup'], {
          queryParams: { email: email },
        });
      }
    } catch (error) {
      this.message.warn(this.textMessage.Unknown);
    } finally {
      this.loading = false;
      this.loadingDataService.set(this.loading);
    }
  }

  loading = false;
  async loginWithGoogle(
    email: string,
    nickname: string,
    avatar: string,
    idtoken: string
  ) {
    try {
      this.loading = true;
      const r = await this.api.loginWithGoogle(
        email,
        nickname,
        avatar,
        idtoken
      );
      if (r.status === 999) {
        this.message.warn(this.textMessage.AccountBanned);
      } else if (r.status !== 0) {
        this.message.warn(this.textMessage.Unknown);
      } else {
        this.afterLogin(r.data);
      }
    } catch (error) {
      this.message.error(this.textMessage.NetworkError);
    }
  }

  afterLogin(data: any) {
    this.router.navigate(['/']);
    this.userService.set(data);
    try {
      Crisp.user.setEmail(data.email);
      Crisp.user.setNickname(data.nickname);
      Crisp.user.setAvatar(this.fileapi.fileurl(data.avatar));
    } catch (error) {}
  }
  
  lineLogin() {
    const timestamp = new Date().getTime();
    // window.open(
    //   `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2004420458&redirect_uri=https://goingbus.com/api/user/linecallback&state=${timestamp}&scope=profile%20openid%20email`
    // );
    window.open(
      `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=2005771230&redirect_uri=https://premlogin.com/api/user/linecallback&state=${timestamp}&scope=profile%20openid%20email`
    );
  }
}
